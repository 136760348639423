import { useState } from 'react'
import DashbardChart from '../../components/Dashboard'
import Transaction from '../../components/Transaction'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  min-width: 420px;
  width: 90%;
	max-width: 1400px;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  border: 1px solid #434651;
  padding: 1rem;
  margin-bottom: 16px;
`

const Title = styled.h2`
  margin-top: -80px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: -40px;
  `};
`

export default function Dashboard() {
  const [ pair, setPair ] = useState({
    id: 2101,
    market: 'FURY-USDC'
  })
  return (<>
      <Title>Dashboard</Title>
			{/* <div style={{ padding: 32, width: '100%', maxWidth: 1600 }}> */}
      <BodyWrapper>
        <DashbardChart pair={pair} setPair={setPair} />
      </BodyWrapper>
      <BodyWrapper>
        <Transaction pair={pair} />
      </BodyWrapper>
			{/* </div> */}
  </>)
}