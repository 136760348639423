import { ChainId } from 'moonbeamswap'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { NavLink, useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import Logo from '../../assets/images/mainlogo.svg'
import { useActiveWeb3React } from '../../hooks'
//import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { YellowCard } from '../Card'
import Settings from '../Settings'
import Menu from '../Menu'

import { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
// import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  text-decoration-style: unset;

  :hover {
    cursor: pointer;
  }
`

const MenuLinkWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const MenuLink = styled(NavLink).attrs({ activeClassName: 'ACTIVE' })`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  text-decoration-style: unset;
  color: ${({ theme }) => theme.white};
  line-height: 36px;
  margin-left: 12px;

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primary1}
  }

  &.ACTIVE {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.primary1};
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  /* color: ${({ theme }) => theme.primary1} */
  color: #db2424;
  :hover {
    transform: rotate(-5deg);
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.STANDALONE]: 'Moonbeam Development',
  [ChainId.ARBITRUM]: 'Arbitrum One',
  [ChainId.ARBITRUM_SEPOLIA]: 'Arbitrum Sepolia',
  [ChainId.ZKFAIR]: 'ZKFair',
  [ChainId.ZKFAIR_TESTNET]: 'ZKFair Testnet',
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  //const [isDark] = useDarkModeManager()

  return (
    <HeaderFrame>
      <RowBetween style={{ alignItems: 'flex-start' }} padding="1rem 1rem 0 1rem">
        <HeaderElement>
          <Title href=".">
            <UniIcon>
              <img style={{ height: 36 }} src={Logo} alt="logo" />
              <span style={{ fontSize: 20, fontWeight: 'bold' }}>FurySwap</span>
            </UniIcon>
          </Title>
        </HeaderElement>
        <HeaderControls>
          <MenuLinkWrap>
            <MenuLink to='swap' isActive={() => pathname === '/swap'}>
              {t('swap')}
            </MenuLink>
            <MenuLink to='pool' isActive={() => pathname === '/pool'}>
            {t('pool')}
            </MenuLink>
            <MenuLink to='dashboard' isActive={() => pathname === '/dashboard'}>
            {t('k-chart')}
            </MenuLink>
          </MenuLinkWrap>
          <HeaderElement>
            <TestnetWrapper>
              {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
            </TestnetWrapper>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} USDC
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElement>
            <Settings />
            <Menu />
          </HeaderElement>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
