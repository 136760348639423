import { createChart, ColorType, CrosshairMode } from 'lightweight-charts';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useSWR from "swr";
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'
import Market from './market';
import { DATA_URL } from '../../constants'

const CHART_PERIODS = [
  { key: '5m', time: 60 * 5 },
  { key: '1h', time: 60 * 60 },
  { key: '5h', time: 60 * 60 * 5 },
  { key: '1d', time: 60 * 60* 24 },
]

const ToolBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 12px;
`

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.black};
  border-radius: 8px;
  padding: 4px;
  border: 1px solid #434651;
  gap: 8px;
  float: left;
  clear: both;
`

const TabItem = styled.div<{ active?: boolean }>`
  cursor: default;
  background-color: ${({ theme, active }) => (active ? theme.primary1 : 'none')} !important;
  border-radius: 4px;
  padding: 0 8px;
  line-height: 28px;
  :hover {
    background-color: ${({ theme }) => theme.bg1};
  }
`

// @ts-ignore
const fetcher = (...args) => fetch(...args).then((res) => res.json())

const ChartComponent = ({ pair, setPair }) => {
  const { t } = useTranslation()
  const [ period, setPeriod ] = useState('5m')
  const chartContainerRef:any = useRef();
  const chart:any = useRef()
  const chartSeries:any = useRef()
  const { data: restData } = useSWR(`${DATA_URL}/candleprices?pair=${pair.id}&period=${period}`, fetcher)

  useEffect(
    () => {
      const handleResize = () => {
        chart?.current?.applyOptions({ width: chartContainerRef.current.clientWidth || 320 });
      };

      chart.current = createChart(chartContainerRef.current, {
        // width: chartContainerRef.current.clientWidth,
        height: 320,
        layout: {
          background: { type: ColorType.Solid, color: 'transparent' },
          textColor: '#fff',
        },
        grid: {
          vertLines: {
            color: 'rgba(255, 255, 255, 0.1)',
          },
          horzLines: {
            color: 'rgba(197, 203, 206, 0.1)',
          },
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        localization: {
          priceFormatter: (price) => {
            return numeral(price).format('0.000e+0');
          }
        },
        rightPriceScale: {
          borderColor: 'rgba(197, 203, 206, 0.8)',
        },
        timeScale: {
          borderColor: 'rgba(197, 203, 206, 0.7)',
        },
      });
      chart?.current?.timeScale().fitContent();

      chartSeries.current = chart.current.addCandlestickSeries({
        upColor: 'green',
        downColor: 'red',
        borderDownColor: 'red',
        borderUpColor: 'green',
        wickDownColor: 'red',
        wickUpColor: 'green',
      });
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        chart.current?.remove();
      };
    },
    []
  );

  useEffect(() => {
    chartSeries?.current?.setData(restData?.rows || [])
    chart?.current?.timeScale().fitContent();
  }, [restData])

  return (
    <div>
      <ToolBar>
        <Market pair={pair} setPair={setPair} />
        <TabWrapper>
          {CHART_PERIODS.map((item) => {
            return (
              <TabItem
                key={item.key}
                active={item.key === period}
                onClick={() => { setPeriod(item.key) }}
              >
                {t(item.key)}
              </TabItem>
            )
          })}
        </TabWrapper>
      </ToolBar>
      <div ref={chartContainerRef} />
    </div>
  );
};

export default ChartComponent