import React, { useRef } from 'react'
import styled from 'styled-components'
import { ChevronDown } from 'react-feather'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import useToggle from '../../hooks/useToggle'
import useSWR from 'swr'
import { DATA_URL } from '../../constants'

const StyledMenuButton = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.black};
  border-radius: 8px;
  padding: 4px;
  border: 1px solid #434651;
  cursor: default;
`

const StyledMenuBtnContent = styled.div`
  cursor: default;
  gap: 6px;
  border-radius: 4px;
  padding: 0 8px;
  line-height: 28px;
  display: flex;
  align-items: center;
  :hover {
    background-color: ${({ theme }) => theme.bg1};
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 8.125rem;
  background-color: ${({ theme }) => theme.bg3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  left: 0rem;
  z-index: 100;
`

const MenuItem = styled.div`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

// @ts-ignore
const fetcher = (...args) => fetch(...args).then((res) => res.json())
export default function Menu({ pair, setPair }) {
  const node = useRef<HTMLDivElement>()
  const [open, toggle] = useToggle(false)
  const { data } = useSWR(`${DATA_URL}/pairs`, fetcher)

  useOnClickOutside(node, open ? toggle : undefined)
  const handleClick = (value) => {
    setPair(value)
    toggle()
  }

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <StyledMenuBtnContent>{pair.market}<ChevronDown size={18} /></StyledMenuBtnContent>
      </StyledMenuButton>
      {open && (
        <MenuFlyout>
          {data?.rows?.map((row) => {
            return (
              <MenuItem onClick={() => { handleClick(row) }} key={row.id}>{row.market}</MenuItem>
            )
          })}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
