import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useSWR from "swr";
import dayjs from 'dayjs';
import { DATA_URL } from '../../constants'

const TableWrapper = styled.div`
`

const Table = styled.table`
  width: 100%;
`

const Th = styled.th`
  font-weight: normal;
  height: 36px;
  background: ${({ theme }) => theme.bg2};
  font-size: 14px;
  text-align: left;
  padding: 0 8px;
  font-weight: bold;
`

const Td = styled.td`
  font-weight: normal;
  /* border-top: 1px solid ${({ theme }) => theme.bg2}; */
  padding: 0 8px;
  font-weight: 400;
`

const Row = styled.tr`
  font-weight: normal;
  height: 32px;
  font-size: 14px;
  :nth-child(even) {
    /* background: ${({ theme }) => theme.bg2}; */
    background: rgba(0,0,0,0.1);
  }
  :hover {
    /* background: ${({ theme }) => theme.bg3}; */
    background: rgba(0,0,0,0.2);
  }
`

const DataDesc = styled.div`
  text-align: center;
  margin: 20px auto 0 auto;
  line-height: 36px;
`

const LoadMore = styled.div`
  line-height: 36px;
  background: ${({ theme }) => theme.primary1};
  width: 65%;
  min-width: 240px;
  margin: 20px auto 0 auto;
  border-radius: 8px;
  cursor: pointer;
`

const Link = styled.a`
  color: ${({ theme }) => theme.primary1};;
`

const typeColorMap = {
  sell: 'red',
  buy: 'green',
  mint: '#fff'
}

const truncate = (stringValue?:string, start:number=8, end?:number) => {
  if (!stringValue) return ''
  if (stringValue.length < 10) return stringValue
  return `${stringValue.substring(0, start)}...${stringValue.substring(stringValue.length - 4)}`
}

// @ts-ignore
const fetcher = (url:any) => fetch(url).then(r => r.json())

const DataList = ({ page, setPage, pair }:any) => {
  const pageSize = 20
  let params = `page=${page}&size=${pageSize}`
  if (pair) {
    params += `&pair=${pair.id}`
  }
  const { data, isLoading } = useSWR(`${DATA_URL}/prices?${params}`, 
    fetcher,
    {
      // revalidateIfStale: false,
      // revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  )

  const count = data?.rows?.length || 0

  return (<>
    {data?.rows?.map(({ id, timestamp, op_type, market, usd_value, price, transaction_hash }) => {
      const time = dayjs(timestamp * 1000)
      return (
        <Row key={id}>
          <Td>{time.format('YY-MM-DD HH:mm:ss')}</Td>
          <Td style={{ color: typeColorMap[op_type] }}>{op_type}</Td>
          <Td>{usd_value}</Td>
          <Td>{market}</Td>
          <Td>{price}</Td>
          <Td style={{ textAlign: 'right' }}>
            <Link target='_blank' rel="noreferrer" href={`https://scan.zkfair.io/tx/${transaction_hash}`}>
            {truncate(transaction_hash)}
            </Link>
          </Td>
        </Row>
      )
    })}
    {isLoading && <tr><td colSpan={6}><DataDesc>Loading...</DataDesc></td></tr>}
    {(!isLoading && count !== pageSize) && <tr><td colSpan={6}><DataDesc>All data loaded</DataDesc></td></tr>}
    {(!isLoading && setPage && count === pageSize) && (
      <tr>
        <td colSpan={6} style={{ textAlign: 'center' }}>
          <LoadMore onClick={() => { setPage(page + 1) }}>Load More</LoadMore>
        </td>
      </tr>
    ) }
  </>)
}

const Component = ({ pair }: any) => {
  const [page, setPage] = useState(1)
  const pages:any = []
  for (let i = 1; i <= page; i++) {
    pages.push(<DataList page={i} pair={pair} setPage={i === page ? setPage: null } key={i} />)
  }

  useEffect(() => {
    setPage(1)
  }, [pair])

  return (
    <TableWrapper>
      <Table style={{ borderSpacing: 0 }}>
        <thead>
          <tr>
            <Th>Date</Th>
            <Th>Type</Th>
            <Th>USD</Th>
            <Th>Market</Th>
            <Th>Price</Th>
            <Th style={{ textAlign: 'right' }}>Link</Th>
          </tr>
        </thead>
        <tbody>
          {pages}
        </tbody>
      </Table>
    </TableWrapper>
  )
}

export default Component